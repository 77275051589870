
import { defineComponent } from "@vue/runtime-core";
import * as Device from "@/utils/Device";
import * as Modals from "@/utils/Modals";
import { getAttachmentManager } from "@/firebase/AttachmentManager";

export default defineComponent({
  props: ["result"],
  methods: {
    displayPictures() {
      Modals.openCarousel(
        this.result.pictures.map((x) => x.url),
        this.onImageError
      );
    },
    async onImageError(src: string) {
      var res = this.result.pictures.find((x) => x.url == src);
      let newUrl = await getAttachmentManager().getDownloadUrl(
        res.firebase_path
      );

      res.url = newUrl;
      var carousel = document.body.querySelector(".se-carousel");
      carousel.parentNode.removeChild(carousel);
      Modals.openCarousel(
        this.result.pictures.map((x) => x.url),
        this.onImageError
      );
    },
  },
});
