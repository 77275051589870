
import Task from "@/model/Task";
import * as TaskManager from "@/firebase/TaskManager";
import { defineComponent } from "@vue/runtime-core";
import * as DateUtils from "@/utils/DateUtils";
import * as Device from "@/utils/Device";
import * as Modals from "@/utils/Modals";
import { getAttachmentManager } from "@/firebase/AttachmentManager";
import * as ImageUtils from "@/utils/ImageUtils";

export default defineComponent({
  props: {
    task: {
      type: Task,
      required: true,
    },
  },
  async setup(props: any) {
    let postponedTask = await TaskManager.getTask(props.task.postponed_task);

    let postponedDate = TaskManager.getTaskStartString(postponedTask);

    let startHour = postponedDate.split(" ")[1];

    postponedDate = postponedDate.split(" ")[0];

    let endDate = TaskManager.getExpectedCompletionDate(postponedTask);

    let endHour = DateUtils.formatTime(endDate);

    let period = startHour + " - " + endHour;
    return {
      postponedDate: postponedDate,
      period: period,
      endDate: Date,
    };
  },
  data() {
    return {
      DateUtils: DateUtils,
      TaskManager: TaskManager,
    };
  },

  methods: {
    displayPicture() {
      Modals.openCarousel(
        this.result.pictures.map((x) => x.url),
        this.onImageError
      );
    },
    async onImageError(src: string) {
      var res = this.result.pictures.find((x) => x.url == src);
      let newUrl = ImageUtils.toImageKit(
        await getAttachmentManager().getDownloadUrl(res.firebase_path)
      );

      res.url = newUrl;
      var carousel = document.body.querySelector(".se-carousel");
      carousel.parentNode.removeChild(carousel);
      Modals.openCarousel(
        this.result.pictures.map((x) => x.url),
        this.onImageError
      );
    },
  },
});
