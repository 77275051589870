
import { defineComponent, nextTick } from "vue";
import * as Lang from "@/i18n/lang";

import SeSimpleTab from "@/components/global/SeSimpleTab.vue";
import { ModuleEnum } from "@/model/ModuleEnum";
import Task from "@/model/Task";
import * as TaskManager from "@/firebase/TaskManager";
import TemperatureResultTable from "@/components/tasks/table/TemperatureResultTable.vue";
import Store from "@/model/Store";
import SeLoadingIndicator from "@/components/global/SeLoadingIndicator.vue";
import TraceabilityResultTable from "@/components/tasks/table/TraceabilityResultTable.vue";
import ActionResultTable from "@/components/tasks/table/ActionResultTable.vue";
import OilResultTable from "@/components/tasks/table/OilResultTable.vue";
import TemperatureControlTable from "@/components/tasks/table/TemperatureControlTable.vue";
import { getStoreManager } from "@/firebase/StoreManager";
import { getEmployeeManager } from "@/firebase/EmployeeManager";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import * as ModuleManager from "@/firebase/ModuleManager";
import { DocumentReference } from "firebase/firestore";

export default defineComponent({
  expose: ["search", "onSearchTextChange"],

  async setup() {
    await getDatabaseManager().initialize();
    await getStoreManager().initialize();

    return {
      visible: getStoreManager().getAvailableStoreOnHistory().length > 0,
    };
  },
  components: {
    SeSimpleTab,
    TemperatureResultTable,
    SeLoadingIndicator,
    TraceabilityResultTable,
    ActionResultTable,
    OilResultTable,
    TemperatureControlTable,
  },

  async mounted() {
    if (this.visible) {
      this.onTabChange();
      this.search();
    }
  },

  methods: {
    onStoreChange(store: Store) {
      var refs = <any>this.$refs;

      for (let i = 0; i < 5; i++) {
        refs.tab.unhideIndex(i);
      }

      var database = getDatabaseManager().getDatabaseByReferenceFromCache(
        store.database
      );

      for (let moduleRef of store.hidden_modules) {
        this.hideModule(moduleRef);
      }
      for (let moduleRef of database.hidden_modules) {
        this.hideModule(moduleRef);
      }

      this.completeDisplay = false;
    },

    hideModule(moduleRef: DocumentReference) {
      var refs = <any>this.$refs;
      let module = ModuleManager.getModuleFromRef(moduleRef);
      var modEnum = module.toEnum();

      switch (modEnum) {
        case ModuleEnum.Temperatures:
          refs.tab.hideIndex(0);
          break;
        case ModuleEnum.Traceability:
          refs.tab.hideIndex(1);
          break;

        case ModuleEnum.Oils:
          refs.tab.hideIndex(2);
          break;

        case ModuleEnum.Action:
          refs.tab.hideIndex(3);
          break;

        case ModuleEnum.TemperatureControl:
          refs.tab.hideIndex(4);
          break;
      }
    },
    onSearchTextChange(search: string) {
      let activePanel = this.getActivePanel();

      let zones = activePanel.querySelectorAll(".expendable-zone");

      for (let zone of zones) {
        let rows = zone.querySelectorAll("tr");

        let headerText = zone.parentNode.firstChild.textContent
          .trim()
          .toLowerCase();

        let flag1 = headerText.includes(search.toLocaleLowerCase());

        let all = true;

        for (let i = 1; i < rows.length; i++) {
          let row = rows[i];

          let name = row.innerText.trim();

          if (!name.toLowerCase().includes(search.toLowerCase()) && !flag1) {
            row.style.display = "none";
          } else {
            row.style.display = "";
            all = false;
          }

          if (all) {
            zone.parentNode.style.display = "none";
          } else {
            zone.parentNode.style.display = "";
          }
        }
      }
    },

    toggleExpend() {
      this.expend = !this.expend;

      for (let panel of this.getAllPanels()) {
        let tableList = panel.childNodes[0].childNodes;

        for (let list of tableList) {
          if (list.tagName == "DIV") {
            let zone = list.querySelector(".expendable-zone");
            let btn = list.querySelector(".expend-btn");

            if (this.expend) {
              zone.classList.remove("close");
              btn.classList.add("rotate");
            } else {
              zone.classList.add("close");
              btn.classList.remove("rotate");
            }
          }
        }
      }
    },
    async search() {
      this.temperatureTasks = [];
      this.traceabilityTasks = [];
      this.cleaningTasks = [];
      this.oilTasks = [];
      this.temperatureControlTasks = [];

      let currentStore = this.getCurrentStore();

      if (currentStore == null) {
        return;
      }

      this.completeDisplay = true;

      await nextTick();

      let refs = <any>this.$refs;
      refs.tab.selectFirstIndex(0);

      let start = refs.search.getStart() as Date;
      // start.setDate(start.getDate()2);-
      let end = refs.search.getEnd();

      let modules = [];

      modules.push(ModuleEnum.Temperatures);
      modules.push(ModuleEnum.Traceability);
      modules.push(ModuleEnum.Action);
      modules.push(ModuleEnum.Oils);
      modules.push(ModuleEnum.TemperatureControl);

      let displayedStores = [];

      displayedStores.push(currentStore);

      let employeeManager = getEmployeeManager();

      let results = await TaskManager.searchTasks(
        "",
        start,
        end,
        displayedStores,
        modules
      );

      console.log(results);

      for (let result of results) {
        if (result.completed_employee != null) {
          console.log(result.completed_employee!.path);
          let target = await employeeManager.getEmployee(
            result.completed_employee!
          );

          if (target != null) {
            result.employee = target;
          }
        }
      }

      this.temperatureTasks = results
        .filter((x) => x.module == ModuleEnum.Temperatures)
        .sort(
          (x, y) =>
            y.completed_timestamp!.seconds - x.completed_timestamp!.seconds
        );

      this.traceabilityTasks = results
        .filter((x) => x.module == ModuleEnum.Traceability)
        .sort(
          (x, y) =>
            y.completed_timestamp!.seconds - x.completed_timestamp!.seconds
        );
      this.cleaningTasks = results
        .filter((x) => x.module == ModuleEnum.Action)
        .sort(
          (x, y) =>
            y.completed_timestamp!.seconds - x.completed_timestamp!.seconds
        );

      this.oilTasks = results
        .filter((x) => x.module == ModuleEnum.Oils)
        .sort(
          (x, y) =>
            y.completed_timestamp!.seconds - x.completed_timestamp!.seconds
        );

      this.temperatureControlTasks = results
        .filter((x) => x.module == ModuleEnum.TemperatureControl)
        .sort(
          (x, y) =>
            y.completed_timestamp!.seconds - x.completed_timestamp!.seconds
        );
    },

    getCurrentStore(): Store {
      let refs = <any>this.$refs;
      return refs.search.getSelectedStore();
    },
    getActivePanel() {
      let refs = <any>this.$refs;

      let index = refs.tab.getSelectedIndex();

      switch (index) {
        case 0:
          return refs.temperaturePanel;
        case 1:
          return refs.traceabilityPanel;
        case 2:
          return refs.oilsPanel;
        case 3:
          return refs.cleaningPanel;
        case 4:
          return refs.temperatureControlPanel;
      }
    },

    getAllPanels() {
      let refs = <any>this.$refs;

      return [
        refs.temperaturePanel,
        refs.traceabilityPanel,
        refs.cleaningPanel,
        refs.oilsPanel,
        refs.temperatureControlPanel,
      ];
    },
    onTabChange() {
      let refs = <any>this.$refs;

      for (let panel of this.getAllPanels()) {
        panel.style.display = "none";
      }
      this.getActivePanel().style.display = "";

      refs.search.resetTextSearch();
    },
  },

  data() {
    let temperatureTasks = [] as Task[];
    let traceabilityTasks = [] as Task[];
    let cleaningTasks = [] as Task[];
    let oilTasks = [] as Task[];
    let temperatureControlTasks = [] as Task[];

    return {
      temperatureTasks: temperatureTasks,
      traceabilityTasks: traceabilityTasks,
      cleaningTasks: cleaningTasks,
      temperatureControlTasks: temperatureControlTasks,
      oilTasks: oilTasks,
      Lang: Lang,
      completeDisplay: false,
      expend: false,
    };
  },
});
